<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong>Accessable Management</strong>
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            class="ml-2"
            v-model="appId"
            placeholder="Select App"
            style="width: 200px;"
            @change="getData"
          >
            <a-select-option
              v-for="(data, index) in dataapplist"
              :key="index"
              :value="data.id"
              >{{ data.appName }}</a-select-option
            >
          </a-select>
          <a-button
            v-if="mode === 'netral' || mode === 'oncreate'"
            ref="addBtn"
            class="ml-2 btn btn-outline-primary"
            :disabled="mode !== 'netral' && mode !== 'oncreate'"
            @click="addNullData"
          >
            Tambah
          </a-button>
          <a-button
            v-if="mode === 'netral'"
            class="btn btn-outline-danger ml-2 mr-2"
            @click="removeOrDelete"
            :disabled="mode !== 'netral'"
          >
            Delete
          </a-button>
          <a-button
            class="btn btn-outline-success ml-2"
            @click="saveChanges"
            v-if="mode === 'onupdate' || mode === 'oncreate'"
          >
            Save Changes
          </a-button>
          <a-button
            class="btn btn-outline-danger ml-2 mr-2"
            @click="cancelaction"
            v-if="mode === 'onupdate' || mode === 'oncreate'"
          >
            Cancel
          </a-button>
        </template>
        <ag-grid-vue
          style="height: 50vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="allowPagination"
          :paginationPageSize="20"
          :cacheQuickFilter="true"
          :quickFilterText="tablequickfilter"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          @cell-value-changed="onCellValueChanged"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          :frameworkComponents="frameworkComponents"
          @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
        >
        </ag-grid-vue>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      mode: 'netral',
      appId: null,
      changedData: [],
      selectedData: null,
      gridApi: null,
      gridOptions: null,
      columnDefs: [
        {
          field: 'menu_name',
          headerName: 'Menu Name',
          pinned: false,
          filter: true,
          editable: true,
          sortable: true,
          resizable: true,
          tooltipField: 'menu_name',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'key',
          headerName: 'key',
          pinned: false,
          filter: true,
          editable: true,
          sortable: true,
          resizable: true,
          tooltipField: 'key',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'route',
          headerName: 'route',
          pinned: false,
          filter: true,
          editable: true,
          sortable: true,
          resizable: true,
          tooltipField: 'route',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'url',
          headerName: 'url',
          pinned: false,
          filter: true,
          editable: true,
          sortable: true,
          resizable: true,
          tooltipField: 'url',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'icon',
          headerName: 'icon',
          pinned: false,
          filter: true,
          editable: true,
          sortable: true,
          resizable: true,
          tooltipField: 'icon',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'urutan',
          headerName: 'urutan',
          cellEditor: 'numericEditor',
          pinned: false,
          filter: true,
          editable: false,
          sortable: true,
          resizable: true,
          tooltipField: 'urutan',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'level',
          headerName: 'level',
          cellEditor: 'numericEditor',
          pinned: false,
          filter: true,
          editable: false,
          sortable: true,
          resizable: true,
          tooltipField: 'level',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'parent',
          headerName: 'parent',
          cellEditor: 'numericEditor',
          pinned: false,
          filter: true,
          editable: false,
          sortable: true,
          resizable: true,
          tooltipField: 'parent',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'isexclude',
          headerName: 'isexclude',
          cellEditor: 'numericEditor',
          // cellEditorParams: {
          //   values: [
          //     {
          //       kode: '0',
          //       keterangan: 'Tidak',
          //     },
          //     {
          //       kode: '1',
          //       keterangan: 'Ya',
          //     },
          //   ],
          //   reffield: 'kode',
          //   reflabel: 'keterangan',
          // },
          pinned: false,
          filter: true,
          editable: true,
          sortable: true,
          resizable: true,
          tooltipField: 'isexclude',
          tooltipComponent: 'customTooltip',
        },
        {
          field: 'caption',
          headerName: 'caption',
          pinned: false,
          filter: true,
          editable: true,
          sortable: true,
          resizable: true,
          tooltipField: 'caption',
          tooltipComponent: 'customTooltip',
        },
      ],
      rowData: [],
      dataapplist: [],
      componentKey: 0,
      allowPagination: localStorage.getItem('gridpagination') || false,
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      tablequickfilter: '',
      whatform: 'manage/permission',
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },
    }
  },
  created () {
    this.gridOptions = {}
    this.getRequirement()
  },
  methods: {
    moment,
    async getRequirement() {
      var res = await lou.customUrlGet('manage/app', false, true)
      if (res) {
        this.dataapplist = this.$g.clone(res.data)
        if (this.dataapplist !== null && this.dataapplist.length !== 0) {
          this.appId = this.dataapplist[0].id
          this.getData()
        }
      } else {
        this.dataapplist = []
      }
    },
    async getData() {
      var res = await lou.readMaster(this.whatform + '/' + this.appId, false, true)
      this.rowData = this.$g.clone(res.data)
    },
    paginationruleschange() {
      this.allowPagination = !this.allowPagination
      localStorage.setItem('gridpagination', this.allowPagination)
      // location.reload()
      this.componentKey += 1
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    async saveChanges() {
      // console.log('this.changedData', this.changedData)
      var fd = this.changedData.length !== 0 ? this.$g.clone(this.changedData) : []
      // var user = this.$store.state.user
      if (fd.length === 0) lou.shownotif('Belum ada perubahan')
      fd.forEach((element) => {
        element.app_Id = this.appId
        if (this.mode === 'oncreate') {
          delete element.id
          if (element.newdata) {
            delete element.newdata
          }
        }
      })
      // console.log('fd', fd)
      // fd[0].tglmulai = fd[0].tglmulai.slice(0, 10)
      // fd[0].tgljatuhtempo = fd[0].tgljatuhtempo.slice(0, 10)
      // fd[0].tglpenilaianterakhir = fd[0].tglpenilaianterakhir.slice(0, 10)
      var res = {}
      if (this.mode === 'oncreate') {
        // console.log('fd', fd)
        res = await lou.createMasterMany(this.whatform, fd, true, true)
      } else {
        res = await lou.updateMasterMany(this.whatform, fd, true, true)
        // console.log('fd', fd)
      }
      if (res) {
        this.mode = 'netral'
        this.changedData = []
        this.getData()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows)
    },
    addNullData() {
      this.mode = 'oncreate'
      var fd = {}
      this.columnDefs.forEach((element, index) => {
        var booltgl = element.cellEditor === 'dateEditor'
        if (booltgl) {
          fd[element.field] = moment().format('YYYY-MM-DD')
        } else if (element.cellEditor === 'numericEditor') {
          fd[element.field] = 0
        } else {
          fd[element.field] = ''
        }
      })
      fd.id = this.$uuid.v4()
      fd.newdata = true
      this.rowData.splice(0, 0, fd)
    },
    removeOrDelete() {
      // console.log('this.selectedData', this.selectedData)
      if (this.selectedData === null) {
        lou.shownotif(
          'Anda belum memilih data atau data yang anda pilih harus tidak boleh lebih dari 1',
        )
      } else {
        if (this.selectedData[0].newdata) {
          var indexTarget = this.rowData.findIndex(
            (x) => x.id === this.selectedData[0].id,
          )
          this.rowData.splice(indexTarget, 1)
        } else {
          this.confirmationDelete(this.selectedData[0].id)
          // send to api
        }
      }
    },
    cancelaction() {
      // console.log('this.mode', this.mode)
      if (this.mode === 'oncreate') {
        var maped = this.rowData.filter((x) => x.newdata === undefined)
        // console.log('maped', maped)
        this.rowData = maped
      } else {
        // var changedRowNode = []
        this.rowData = this.$g.clone(this.backupRowData)
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
      this.refreshTable()
      // for (let i = 0; i < this.input.undoInput; i++) {
      //   this.gridApi.undoCellEditing()
      // }
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    async confirmationDelete(id) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.deleteMaster(this.whatform, id, true, true)
          this.getData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    refreshTable() {
      var params = {
        force: true,
        suppressFlash: false,
      }

      this.gridOptions.api.refreshCells(params)
      // console.log('kerefresh kok')
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    undo() {
      this.gridApi.undoCellEditing()
    },
    redo() {
      this.gridApi.redoCellEditing()
    },
    currencyFormatter(params) {
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    titleFormat(word) {
      return lou.capitalizeTheFirstLetterOfEachWord(word)
    },
  },
}
</script>

<style>

</style>
